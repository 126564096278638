import { BigNumber, ethers } from 'ethers';
import { useContext } from 'react';
import { Context } from '../Store';
import popsABI from '../contractABIs/popsABI.json';


declare const window:any;
let provider:any = undefined;
let popsContract:any = undefined;
const FANTOM_NETWORK_ID = "250";
enum Tokens {
  FTM = "0x0000000000000000000000000000000000000000",
  USDC = "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
};

//Provider and Initialization
export const initializeEthers = async (dispatch:any) => {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(0);
    const addr = await signer.getAddress();

    if (window.ethereum.networkVersion === FANTOM_NETWORK_ID) {
      dispatch({type:"onFantomNetwork", content:true});
    }
    dispatch({type: 'walletContextDetected', content: true });
    dispatch({type: 'triggerAll', content: false});

    popsContract = new ethers.Contract(
      "0xC88bEbD7C4B947A6973398391190B3FcfB46e334",
      popsABI,
      signer
    );

    console.log(popsContract);

    return addr;
  } catch (error) {
    console.log(error);
    dispatch({type: 'walletContextDetected', content: false });
    dispatch({type:"onFantomNetwork", content: false});
    return undefined;
  }
}

export const checkNetwork = () => {
  try {
    return (window.ethereum.networkVersion === FANTOM_NETWORK_ID);
  } catch {
    return false;
  }
}

export const checkTotalSupply = async (dispatch:any) => {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const supplyOfPops = await popsContract.totalSupply();
    //console.log(supplyOfPops.toNumber());
    dispatch({type: 'totalPopsSupply', content: supplyOfPops.toNumber()});
  } catch(error) {
    return console.log(error);
  }
}

export const mintCustom = async (dispatch:any, amountToMint:any, token:any) => {
  const collection = "0x0000000000000000000000000000000000000000";
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(0);
    let cost;
    let value;
    let totalyPayout;
    if(token === Tokens.FTM) {
      cost = 50;
      totalyPayout = cost * amountToMint;
      value = ethers.utils.parseUnits(totalyPayout.toString(),'ether');
    }
    else if(token === Tokens.USDC) {
      cost = 25;
      totalyPayout = cost * amountToMint;
      value = 0;
    }
    else {
      cost = 50;
      totalyPayout = cost * amountToMint;
      value = ethers.utils.parseUnits(totalyPayout.toString(),'ether');
    }
    let gasLimitForTx = amountToMint * 280000;
    console.log(totalyPayout);
    try {
      const connectedpopsContract = await popsContract.connect(signer);
      const tx = await connectedpopsContract.mintCustom(token, amountToMint, collection,
        {gasLimit: gasLimitForTx, "value": value}
      );
      tx.wait();
    } catch(error) {
      return console.log(error);
    }
  } catch(error) {
    return console.log(error);
  }
}

export const mint = async (dispatch:any, amountToMint:any) => {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(0);
    const cost = 30;
    const totalyPayout = cost * amountToMint;
    let gasLimitForTx = amountToMint * 280000;
    console.log(totalyPayout);
    try {
      const connectedpopsContract = await popsContract.connect(signer);
      const tx = await connectedpopsContract.mint(amountToMint,
        {gasLimit: gasLimitForTx, "value": ethers.utils.parseUnits(totalyPayout.toString(),'ether')}
      );
      tx.wait();
    } catch(error) {
      return console.log(error);
    }
  } catch(error) {
    return console.log(error);
  }
}
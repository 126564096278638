import React, { useContext, useEffect, useState } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import discord_img from "./images/discord.png";
import twitter_img from "./images/twitter.png";
import menu_img from "./images/menu.png";
import ftm from "./images/FTM-blk.png";
import usdc from "./images/USDC-blk.png";
import nftkey from "./images/nftkey.png";
import logo from "./images/leukemia-lymphoma-logo.jpg";
import drops from "./images/2-drops.png";
import gb_logo from "./images/giving-block.png";
import lls_logo from "./images/logo.jpg";
import mm_logo from "./images/metamask-logo.png";
import nfts from "./images/fcancer-nfts.png";
import mace from "./images/mace.png";
import funeral from "./images/funeral.png";
import aj from "./images/aj.png";
import neal from "./images/neal.png";

import { mint, checkTotalSupply, initializeEthers, mintCustom } from "./functions/ethersFunctions";
import ConnectWallet from "./components/ConnectWallet";
import { Context } from "./Store";

const menuToggle = () => {
    const navlinks = document.querySelector('.nav-links');
    navlinks?.classList.toggle('mobile-menu');
}
function App() {

  //const [active, setActive] = React.useState("Public");
  const [state, dispatch]:any = useContext(Context);
  const [spendInput, setSpendInput] = useState('');
  const [currency, setCurrency] = useState('0x0000000000000000000000000000000000000000');
  const [opacityFTM, setOpacityFTM] = useState(1);
  const [opacityUSDC, setOpacityUSDC] = useState(1);

  function setFTM():any {
    setOpacityFTM(0.5);
    setOpacityUSDC(1);
    setCurrency("0x0000000000000000000000000000000000000000");
  };

  function setUSDC():any {
    setOpacityUSDC(0.5);
    setOpacityFTM(1);
    setCurrency("0x04068DA6C83AFCFA0e13ba15A6696662335D5B75");
  };

  useEffect(() => {
    async function getInit() {
        await initializeEthers(dispatch);
    }
    getInit();
  },[]);

  useEffect(() => {
    async function getSupply() {
        await checkTotalSupply(dispatch);
        console.log(currency);
    }
    getSupply();
  },[state.walletAddress, currency]);

  return (
    <Router>
    <div className="App">
           <nav className="navbar">
                <h1 className="logo"><a>FCANCER</a></h1>
                <ul className="nav-links">
                    <li><a href="https://www.lls.org">Leukemia & Lymphoma Society</a></li>
                    <li><a href="https://www.kingcares2022.com">King Cares</a></li>
                    <li><Link to="/"><ConnectWallet/></Link></li>
                </ul>
                <img src={menu_img} alt="" className="menu-btn" onClick={menuToggle}/>
            </nav>
            <header>
            <div>
            <section className="drops">
            <div className="title">
            <h1>Donate today.</h1>
            <h2>{state.totalPopsSupply}/15,000</h2>
            </div>
            <div className="row">
                <div className="col">
                <div className="mint-text">
                    <h4>All donations will be matched by The Giving Block and donated to LLS.</h4>
                    <h4>Each NFT is equal to a donation of 50 FTM or $25</h4>
            <div className = "tokens">
                <img alt="FTM" src={ftm} style={{opacity: opacityFTM}} onClick={() => setFTM()}/>
                <img alt="USDC" src={usdc} style={{opacity: opacityUSDC}} onClick={() => setUSDC()}/>
                <h4>Select Fantom or USDC</h4>
            </div>

                    {
                            !state.walletAddress ?
                            <h3>Connect Wallet to donate.</h3>
                            
                            :
                            <>
                                <input type="number" value={spendInput} onInput={e => setSpendInput((e.target as HTMLInputElement).value)} placeholder="Select Number of NFTs to Mint"/>
                                <br/>
                                <br/>
                                <div className="fade">
                                <div className="card">
                                    <img src={logo} alt=""/>
                                    <div className="bottom-card">
                                    <button onClick={() => mintCustom(dispatch, spendInput, currency)}  disabled={false}>Donate</button>
                                    </div>
                                </div>
                                </div>  
                            </>
                    }
                    <h4>Click the card to submit your donation.</h4>
                </div>
                </div>
                <div className="col">
                </div>
            </div>
            </section>
            </div>
            </header>
            <section className="nft-pay">
            <div className="row">
                <div className="col">
                    <h2>If you are new to Web3 and NFTs, we have included an alternative payment option.</h2>
                    <h2>Credit card donations are $30.</h2>
                <iframe height="610" src="https://payments.creatify.art/iframe/iframe_pay/a099d70a-f1ea-45ee-aefd-bac2882dc542"></iframe>
                 </div>
                <div className="col">

                <div className="fade">
                <img className="two-drops" src={drops} alt=""/>
                </div>

                <h1>Donate with a credit card</h1>
                <p>NFTPay offers a fast and simple solution for purchasing NFTs with your card.</p>
                <p>Once you make a donation and mint your FCANCER NFT, your NFT will be stored safely with NFTpay. This way you can donate to a great cause and receive a unique NFT as a thank you.</p>
                <p>Everyone has their own unique story about how they got started with NFTs and we are happy to be a part of your journey.</p>
                </div>
            </div>
            </section>
            <section className="info">

                <h1>LLS IS A GLOBAL LEADER IN THE FIGHT AGAINST BLOOD CANCER.</h1>
                
                <h4>Since 1949, LLS has invested nearly $1.3 billion in groundbreaking research, pioneering many of today's most innovative approaches.</h4>
                <h4>LLS is the largest nonprofit dedicated to creating a world without blood cancers.</h4>
            </section>
            <section className="drops">
                <div className="nfts">
                <h2>FCANCER COLLECTIBLES</h2>
                <h4>Each donation comes with a randomly generated collectible that will be sent to your web3 wallet.</h4>
                <h4>These collectibles exist on the blockchain as NFTs (non-fungible token).</h4>
                <h4>NFTs are a way to prove that you own something. This means that you can trade them with other people in the community or collect other NFTs that you like.</h4>
                </div>
            </section>
            <div className="fade">
                <img className="nft-samples" src={nfts} alt=""/>
            </div>
            <section className="wallets">
            <div className="wallet-cards">
            <div className="row">
                <div className="col">
                    <h1>Are you new to Web3?</h1>
                    <p>You can use a wallet like Metamask to safely store your FTM and other crypto currencies. This wallet will be installed as a browser extension and serve as your account as you explore new projects.</p>
                </div>
                <div className="card">
                    <img src={mm_logo} alt=""/>
                    
                        <p>Metamask is the most popular software wallet and browser extension. Metamask allows you to store mainnet FTM and interact with Fantom dApps.</p>
  
                    <div className='card-links'>
                    <ul>
                    <li><a href="https://metamask.io">Download Metamask </a></li>
                    <li><a href="https://docs.fantom.foundation/tutorials/set-up-metamask">Set up Metamask on Fantom</a></li>
                    </ul>
                    </div>
                </div>
            </div>
            </div>
            </section>
            <section className="team">
            <div className="partner">
            <div className="row">
                <div className="col">
                <a href="https://www.lls.org/who-we-are"><img className="team-member" src={lls_logo} alt=""/></a>
                </div>
                <div className="col">
                <a href="https://thegivingblock.com"><img className="team-member" src={gb_logo} alt=""/></a>
                </div>
            </div>
            </div>
            </section>
            <section className="team">
            <div className="team-members">
            <h1>The Team</h1>
            <div className="row">
            <div className="col">
                <img className="team-member" src={neal} alt=""/>
                <h3>Neal</h3>
                <h4>Marketing Lead</h4>
                </div>
                <div className="col">
                <img className="team-member" src={aj} alt=""/>
                <h3>AJ</h3>
                <h4>Operations Manager</h4>
                </div>
                <div className="col">
                <a href="https://twitter.com/yolofinancial"><img className="team-member" src={funeral} alt=""/></a>
                <h3>Funeral</h3>
                <h4>Designer/ UI Dev</h4>
                </div>
                <div className="col">
                <a href="https://twitter.com/CryptoMacePapa"><img className="team-member" src={mace} alt=""/></a>
                <h3>Mace Papa</h3>
                <h4>Smart Contract Dev</h4>
                </div>
            </div>
            </div>
            </section>
            <section className="footer">
                <div className="social-links">
                    <a href="https://twitter.com/fcancer_nfts"><img src={twitter_img} alt=""/></a>
                    <a href="https://discord.gg/KRpT7UFgfB"><img src={discord_img} alt=""/></a>
                    <a href="https://nftkey.app"><img src={nftkey} alt=""/></a>
                  </div>
            </section>
        </div>
        </Router>
    );
}

export default App;
